<template>
    <div class="daily-report">
    <header class="daily-report__header">
      <div class="daily-report__info">
        <h2>每日總表</h2>
        <span class="daily-report__doctor-name">{{ staffName }}</span>
        <button class="daily-report__btn-select-doctor" @click="selectDoctorIsActive = true">選擇醫師</button>
      </div>
      <div class="daily-report__date">
        <button class="daily-report__btn-prev" @click="addDay(-1)"></button>
        <button class="daily-report__btn-next" @click="addDay(1)"></button>
        <!-- <span class="daily-report__current-date">{{ dateFormated }}</span> -->
        <datepicker class="reservation-time__datepicker daily-report__btn-select-date" inputFormat="yyyy.MM.dd" v-model="date" />
        <!-- <button class="daily-report__btn-select-date">選擇日期</button> -->
      </div>
      <front-desk-menu :active-item="'daily-report'" />
    </header>
    <main class="daily-report__main">
      <div class="daily-report__outpatient-wrap" id="outpatient-wrap" :style="{width: wrapWidth}">
        <span v-for="treatmentType in treatmentTypes" :key="treatmentType">{{treatmentType.name}}</span>
      </div>
      <div class="daily-report__calendar" id="calendar-wrap" :style="{width: wrapWidth}">
        <!-- 縱軸 時間 -->
        <div class="daily-report__time">
          <div v-for="time in times" :key="time"><strong>{{ time }}</strong></div>
        </div>
        <div class="daily-report__outpatient" v-for="treatmentType in treatmentTypes" :key="treatmentType">
          <div v-for="time in times" :key="time" :set="reservation = findReseravation(treatmentType.id, time)" class="daily-report__period">
            <div class="period" v-if="reservation">
              <div class="period__img">
                <img src="https://picsum.photos/id/1005/120/120" alt="">
              </div>
              <div class="period__info">
                <div class="period__row">
                  <span class="period__name">{{reservation.patientName}}</span>
                  <span class="period__name">{{reservation.patientPhoneLast4Digits}}</span>
                </div>
                <div class="period__row">
                  <span class="period__date">{{reservation.patientType}} / </span>
                  <span class="period__date">{{formatBirthDate(reservation.patientBirthDate)}} / </span>
                  <span class="period__date">{{reservation.patientRemark1}}</span>
                </div>
                <div class="period__row">
                  <span class="period__date">處置時間 {{reservation.treatmentStart}} ({{reservation.treatmentDuration}}分鐘)</span>
                </div>
                <div class="period__row">
                  <span class="period__date">預約時間 {{reservation.scheduleTime}}</span>
                </div>
                <div class="period__row">
                  <span class="period__course">{{reservation.treatmentName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 選擇醫生 -->
    <div :class="{'modal-ui': true, 'is-active': selectDoctorIsActive}">
      <div class="modal-ui__overlay">
        <div class="modal-ui__inner">
          <header class="modal-ui__header">
            <h3>選擇醫師</h3>
            <div class="site-search">
              <input type="text" v-model="keyword">
              <button>搜尋</button>
            </div>
            <button class="modal-ui__btn-close" @click="selectDoctorIsActive = false"></button>
          </header>
          <div class="modal-ui__content">
            <div class="modal-ui__employee-list">
              <button v-for="(staff, index) in filteredStaffs" :key="index" class="modal-ui__btn-name" :class="{ 'is-active': staff.id === selectStaffId }"  @click="onSelectStaff(staff.id, staff.fullName)">{{staff.fullName}}</button>
            </div>
            <!-- <div class="modal-ui__row">
              <button class="modal-ui__btn-cancel" @click="selectDoctorIsActive = false">取消</button>
              <button class="modal-ui__btn-confirm" @click="">確定</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 選擇醫生 -->
    <loading :isActive="isLoading" />
  </div>
</template>
<script>
import Datepicker from 'vue3-datepicker'
import { apiGetTreatmentTypes, apiGetDailyReport, apiGetStaffs } from '../api/v1/reservations'
import { formatDate, formatTimeHHmm, formatTWBirthDay } from '../utils/utils'
import Loading from '../components/Loading.vue'
import FrontDeskMenu from '../components/FrontDeskMenu.vue'

export default {
  name: 'DailyReport',
  components: {
    Datepicker,
    Loading,
    FrontDeskMenu
  },
  watch: {
    searchParams () {
      console.log(`weekday: ${this.date.getDay()}`)
      apiGetDailyReport(this.selectStaffId, { date: this.dateFormated }).then(data => {
        if (!data.error) {
          this.reservations = data
        }
      })
    }
  },
  data () {
    return {
      keyword: '',
      selectStaffId: 0,
      staffName: '',
      treatmentTypes: [],
      reservations: [],
      staffs: [],
      date: new Date(),
      selectDoctorIsActive: false,
      isLoading: true
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    filteredStaffs: function () {
      if (this.staffs) {
        return this.staffs.filter(staff => {
          return staff.fullName.includes(this.keyword)
        })
      }
      return []
    },
    schedules () {
      const data = []
      for (const treatmentType of this.treatmentTypes.entries) {
        const scheduleOfTheType = []
        for (const time of this.times.entries) {
          const reservation = this.reservations.find(r => r.treatmentTypeId === treatmentType.id && r.scheduleTime === time)
          scheduleOfTheType.push(reservation)
        }
        data.push(scheduleOfTheType)
      }
      return data
    },
    reservedDateTimeList () {
      return this.reservations.map(m => `${m.scheduleDate} ${m.scheduleTime}`)
    },
    searchParams () {
      return [this.selectStaffId, this.date]
    },
    dateFormated () {
      return formatDate(this.date)
    },
    wrapWidth () {
      return 240 * this.treatmentTypes.length + 'px'
    },
    dailyStartTime () {
      return this.date.getDay() === 6 ? 10 : 12
    },
    times () {
      const endHour = 21
      const times = []
      let h = this.dailyStartTime
      while (h <= endHour) {
        let m = 0
        const h2 = `${h}`.padStart(2, '0')
        while (m < 60) {
          const m2 = `${m}`.padStart(2, '0')
          times.push(`${h2}:${m2}`)
          m += 20
          if (h === endHour) {
            break
          }
        }
        h += 1
      }
      return times
    }
  },
  methods: {
    loadData () {
      this.isLoading = true
      apiGetTreatmentTypes().then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.treatmentTypes = data
        }
      })
      apiGetStaffs({ jobName: '醫生' }).then(data => {
        if (!data.error) {
          this.staffs = data.content
          if (this.staffs.length > 0) {
            this.selectStaffId = this.staffs[0].id
            this.staffName = this.staffs[0].fullName
          }
        }
      })
    },
    addDay (day) {
      var date = new Date(this.date)
      date.setDate(date.getDate() + day)
      this.date = date
    },
    onSelectStaff (staffId, staffName) {
      this.selectStaffId = staffId
      this.staffName = staffName
      this.selectDoctorIsActive = false
    },
    findReseravation (treatmentTypeId, time) {
      const date1 = new Date(`${this.dateFormated} ${time}`)
      const date2 = new Date(date1.getTime() + 20 * 60000)
      const time2 = formatTimeHHmm(date2)
      return this.reservations.find(r => r.treatmentTypeId === treatmentTypeId && (r.treatmentStart >= time && r.treatmentStart < time2))
    },
    formatBirthDate (bdate) {
      return formatTWBirthDay(bdate)
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-ui {
  &__btn-delete {
    background-color: #f52b08;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #fff;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
  &__btn-cancel {
    background-color: #ffffff;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #525252;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
}
.period {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.daily-report__time {
  top: 2rem;
}
.daily-report__calendar {
  padding-top: 2rem;
}
.daily-report__outpatient {
  flex: 0 0 26rem;
}
// .daily-report__main {
//    width: fit-content;
// }
</style>
